import { sprintf, _x } from '@wordpress/i18n';

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const modalForm = document.querySelector('.header-hp__search__form');
  let firstFocus = true;

  if (findstr && modalForm) {
    findstr.hooks.addAction('searchResults', 'hp-header', (results, group) => {
      if ('hp-header' === group) {
        if ('' === results.query) {
          modalForm.classList.remove('dropdown-active');
        } else {
          modalForm.classList.add('dropdown-active');
        }
      }
    });

    findstr.hooks.addAction(
      'afterSearchResults',
      'hp-header',
      (results, group) => {
        if ('hp-header' === group) {
          const resultsBtn = modalForm.querySelector(
            '.findstr-search-result-all-results'
          );
          const resultsBtnLabels = modalForm.querySelectorAll(
            '.findstr-search-result-all-results .btn-label'
          );

          if (resultsBtn) {
            resultsBtn.setAttribute(
              'href',
              window.theme.home_url + '?s=' + results.query
            );
          }

          if (0 < resultsBtnLabels.length) {
            resultsBtnLabels.forEach((resultBtnLabel) => {
              resultBtnLabel.textContent = sprintf(
                /* translators: %s is number of results */
                _x('All results (%s)', 'findstr all results button', 'vtx'),
                results.totalHits
              );
            });
          }
        }
      }
    );

    modalForm.addEventListener('focusin', function () {
      if (firstFocus) {
        firstFocus = false;
      } else if (
        '' !== modalForm.querySelector('#hp-header_default-search').value
      ) {
        modalForm.classList.add('dropdown-active');
      } else {
        modalForm.classList.remove('dropdown-active');
      }
    });

    modalForm.addEventListener('focusout', function () {
      modalForm.classList.remove('dropdown-active');
    });
  }
});
